import Vue from "vue";

const successNotification = (message) => {
  return Vue.$toast.success(message, {
    position: "top-right",
    timeout: 2000,
    closeOnClick: true,
    closeButton: "button",
    icon: true,
    rtl: false,
  });
};

const errorNotification = (message) => {
  return Vue.$toast.error(message, {
    position: "top-right",
    timeout: 2000,
    closeOnClick: true,
    closeButton: "button",
    icon: true,
    rtl: false,
  });
};

export { successNotification, errorNotification };
